import { createAction, props } from '@ngrx/store'
import {
  FleetCustomerGetDataResponse,
  AuthorizedFleetCustomer,
  FleetOrderDetailsColumns,
} from '../../models/fleet-customer.models'

export const SelectFleetCreator = (
  fleetAccountId: string,
  fleetCompanyId: string,
  navigateTo: string | null = null,
  firstChange = false,
  preserveWithoutNavigating = false,
  doNotTrack = false
): {
  payload: {
    fleetAccountId: string
    fleetCompanyId: string
    navigateTo: string
    firstChange: boolean
    preserveWithoutNavigating: boolean
    doNotTrack: boolean
  }
} => ({
  payload: {
    fleetAccountId,
    fleetCompanyId,
    navigateTo,
    firstChange,
    preserveWithoutNavigating,
    doNotTrack,
  },
})

export const userActions = {
  SELECT_FLEET_FROM_DROPDOWN: createAction(
    '[Select Fleet Dropdown] Manual Select Fleet',
    SelectFleetCreator
  ),

  SELECT_FLEET_FROM_ORIGIN: createAction(
    '[User Effects] Select Origin Fleet',
    SelectFleetCreator
  ),

  SELECT_FLEET_FROM_FX: createAction(
    '[User Effects] Select Fleet',
    SelectFleetCreator
  ),

  GET_FLEET_CUSTOMER_DATA: createAction(
    '[Fleet Customer Api] Get Fleet Customer Data'
  ),

  GET_FLEET_CUSTOMER_DATA_SUCCESS: createAction(
    '[Fleet Customer Api] Get Fleet Customer Data Success',
    props<FleetCustomerGetDataResponse>()
  ),

  GET_FLEET_CUSTOMER_DATA_ERROR: createAction(
    '[Fleet Customer Api] Get Fleet Customer Data Error'
  ),

  GET_PRODUCTS: createAction('[Product Api] Get Products'),

  GET_PRODUCTS_SUCCESS: createAction(
    '[Product Api] Get Products Success',
    (res: any) => ({ payload: res })
  ),

  GET_PRODUCTS_ERROR: createAction('[Product Api] Get Products Error'),

  CHANGE_FLEET_ORDER_LINE_ITEM_COLUMNS: createAction(
    '[Fleet Order Item List] Change Fleet Order Line Item Columns',
    (
      fleetCustomerId: string,
      fleetOrderDetailsColumns: FleetOrderDetailsColumns
    ) => ({
      payload: {
        _id: fleetCustomerId,
        fleetOrderDetailsColumns,
      },
    })
  ),

  CHANGE_FLEET_ORDER_LINE_ITEM_COLUMNS_SUCCESS: createAction(
    '[Fleet Order Item List] Change Fleet Order Line Item Columns Success',
    props<{ payload: { fleetCustomer: AuthorizedFleetCustomer } }>()
  ),

  CHANGE_FLEET_ORDER_LINE_ITEM_COLUMNS_ERROR: createAction(
    '[Fleet Order Item List] Change Fleet Order Line Item Columns Error'
  ),

  CHANGE_LAST_SEEN_VERSION: createAction(
    '[Fleet Customer Api] Change Last Seen Version'
  ),

  CHANGE_LAST_SEEN_VERSION_SUCCESS: createAction(
    '[Fleet Customer Api] Change Last Seen Version Success',
    props<{ payload: { fleetCustomer: AuthorizedFleetCustomer } }>()
  ),

  CHANGE_LAST_SEEN_VERSION_ERROR: createAction(
    '[Fleet Customer Api] Change Last Seen Version Error'
  ),
}
